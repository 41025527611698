.section-management-page {
  td.active {
    font-weight:bold;
  }

  table tr i {
    margin-right:10px;
    cursor: grab;
  }

  .sortable-helper {
    z-index: 10;
  }
}
