.doctor-management-page {
  .doctor-search {
    margin-top: 10px;

    input {
      width: 100%;
      height:2.5em;
      padding:10px;
      font-size:1.3em;
    }
  }

  .doctor-card {
    height:500px;
  }
}
