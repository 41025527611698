.edit-doctor-page {
  .image-label {
    display:block;
    cursor:pointer;
  }

  label {
    margin-top:20px;
  }
}
