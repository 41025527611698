@import './variables';

nav.navbar {
  height: $headerHeight;
  background: $headerColor;

  a {
    color: #FFF;

    &:hover,&:focus {
      background:none !important;
    }
  }

  .navbar-right {
    margin-top:30px;
  }
}
