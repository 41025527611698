.outreach-coordinators-page {
  .outreach-coordinator {
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;

    input {
      margin-bottom:20px;
    }
  }
}
