.page-editor {
  background:#FFF;
  padding:1em;
  border:1px solid #EEE;

  input {
    margin-bottom:20px;
  }

  #tiny-editor {
    height: 600px;
  }

  &-actions {
    margin-bottom:10px;
  }

  &-list-text {
    font-size: 18px;
  }

  &-code-container {
    margin: 20px 0;
  }

  &-code {
    display: flex;
    flex-direction: column;
  }
}
