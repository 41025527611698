.admins-page {
  hr {
    border-color:#DDD;
  }

  .add-user-container {
    margin-top:20px;
    margin-bottom:20px;

    input {
      margin-left:10px;
      margin-right:10px;
      width: 200px;
    }
  }
}
